export default {
    "support": {
        "helpAndSupport": "Помощь и поддержка",
        "newsFeed": "Лента новостей",
        "gos24Info": "Информация о Gos24.КОННЕКТ",
        "knowledgeBase": "База знаний",
        "instructions": "Инструкции по работе с сервисом",
        "techSupportChat": "Чат технической поддержки",
        "contactTechSupport": "Связаться с технической поддержкой",
        "newsFeed": "Лента новостей",
        "addNews": "Добавить новость",
        "noNewNews": "Новых новостей нет",
        "expand": "Раскрыть",
        "centerTitle": "Центр поддержки Gos24.КОННЕКТ",
        "addSection": "Добавить секцию",
        "addChapter": "Добавить главу",
        "addPage": "Добавить страницу",
        "viewAllPages": "Смотреть все страницы",
        "sections": "Разделы",
        "close": "Закрыть",
        "pageNotFound": "Страница отсутствует",
        "archive": "Архив",
        "lastUpdate": "Последнее обновление",
        "editSection": "Редактировать секцию",
        "editChapter": "Редактировать главу",
        "editPage": "Редактировать страницу",
        "displayOnMain": "Выводится на главной",
        "notDisplayedOnMain": "Не выводится на главной",
        "thankYouHelped": "Спасибо, помогло",
        "didNotHelp": "Не помогло",
        "askQuestion": "Задать вопрос",
        "usefulTips": "Еще немного полезных советов",
        "comments": "Комментарии",
        "base_search": "Поиск по базе знаний",
        "section_select": "Выбрать секцию"
    }
}