export default {
    "comment": {
        "showOnly": "Показать только",
        "blocksLeft": "Блоки с левой стороны",
        "save": "Сохранить",
        "send": "Отправить",
        "cancel": "Отменить",
        "addComment": "Добавить комментарий",
        "updated": "Комментарий обновлен",
        "added": "Комментарий добавлен",
        "error": "Ошибка",
        "updated": "Изменено",
        "reply": "Ответить",
        "edit": "Редактировать",
        "open": "Открыть комментарий",
        "add_task": "Добавить задачу",
        "share": "Поделиться",
        "delete": "Удалить",
        "reply_to": "Ответ на комментарий",
        "edit_comment": "Редактировать комментарий",
        "reply_to_comment": "Ответить на комментарий",
        "today": "Сегодня",
        "yesterday": "Вчера",
        "loadMore": "Загрузить еще ({ count })",
        "commentDeleted": "Комментарий удалён",
        "deleteError": "Ошибка удаления",
        "reply": "Ответить",
        "edit": "Редактировать",
        "openComment": "Открыть комментарий",
        "addTask": "Добавить задачу",
        "copy": "Копировать",
        "share": "Поделиться",
        "delete": "Удалить",
        "messageCopied": "Сообщение скопировано",
        "copyError": "Ошибка копирования"
    }
}