export default {
    "meeting": {
        "addParticipant": "Добавить участника",
        "search": "Поиск",
        "close": "Закрыть",
        "new": "Новая",
        "online": "Онлайн",
        "ended": "Завершена",
        "records": "Записи",
        "record": "Запись",
        "emptyList": "Список пуст",
        "openNewWindow": "Открыть в новом окне",
        "linkCopied": "Ссылка скопирована",
        "noData": "Нет данных",
        "drawerTitle": "Редактирование встречи",
        "conferenceName": "Название конференции",
        "description": "Описание",
        "startDateTime": "Дата и время начала",
        "duration": "Длительность",
        "participants": "Участники",
        "addParticipant": "Добавить участника",
        "added": "Добавлено",
        "save": "Сохранить",
        "create": "Создать",
        "editConference": "Редактировать конференцию",
        "createConference": "Создать конференцию",
        "requiredField": "Обязательно для заполнения",
        "participant": "участник",
        "participantGen": "участника",
        "participantsGen": "участников",
        "conferenceCreated": "Конференция создана",
        "error": "Ошибка",
        "conferenceUpdated": "Конференция обновлена",
        "warning": "Предупреждение",
        "confirmEndConference": "Вы действительно хотите завершить конференцию?",
        "cancel": "Закрыть",
        "end": "Завершить",
        "conferenceEnded": "Конференция завершена",
        "conference": "Конференция",
        "confirmDeleteConference": "Вы действительно хотите удалить конференцию?",
        "delete": "Удалить",
        "conferenceDeleted": "Конференция удалена",
        "connect": "Подключиться",
        "viewRecords": "Посмотреть записи",
        "restartConference": "Перезапустить конференцию",
        "endConference": "Завершить конференцию",
        "inviteLink": "Ссылка для приглашения",
        "share": "Поделиться",
        "edit": "Редактировать",
        "no_data": "Нет даты",
        "author": "Вы автор",
        "hide": "Скрыть",
        "more": "Подробнее",
        "participants": "Список участников",
        "connect": "Подключиться",
        "videos": "Видеозаписи",
        "author2": "Автор",
        "moderator": "Модератор",
        "not_fount": "Собрание не найдено или удалено",
        "hour": "час",
        "hours": "часа",
        "of_hours": "часов",
        "minute": "минута",
        "minutes": "минуты",
        "of_minutes": "минут"
    }
}