export default {
    "upload": {
        "drag_file": "Нажмите или перетащите файл в эту область для загрузки",
        "close": "Закрыть",
        "save": "Сохранить",
        "error": "Ошибка",
        "max_file_size": "Максимальный размер файла {size}МБ",
        "max_file_h_w": "Минимальная высота и ширина изображения {size}px",
        "upload": "Загрузить"
    }
}