export default {
    "history": {
        "noData": "Нет данных",
        "connect": "Коннект",
        "action": "Действие",
        "property": "Свойство",
        "fromOrganization": "От организации",
        "was": "Было",
        "became": "Стало",
        "moreDetails": "Подробнее",
        "date": "Дата",
        "collapse": "Свернуть",
        "expand": "Развернуть",
        "close": "Закрыть",
        "today": "Сегодня",
        "yesterday": "Вчера"
    }
}